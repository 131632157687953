import React from 'react';
import useFetch from 'react-fetch-hook';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';

import { IPagesData } from './interfaces/pages';

import { Page } from './components/Page.component';
import { ResultPage } from './components/ResultPage.component';
import { PagesDataContext, EMPTY_PAGES_DATA } from './contexts/PagesData.context';

import './App.css';
import backgroundImage from './images/bg.png'

const RouteParams = (Component: React.FC<any>) => {
    return ({ match, location }: any) => <Component routeParams={match.params} queryParams={queryString.parse(location.search)} />;
};

export function App() {
    const { isLoading: isPagesDataLoading, data: pagesData, error } = useFetch<IPagesData>(
        `http://widm.ewnetworks.eu/static/data.json`
    );

    return (
        <PagesDataContext.Provider value={!isPagesDataLoading ? pagesData! : EMPTY_PAGES_DATA}>
            <div className="App" style={{ backgroundImage:`url(${backgroundImage})` }}>
                <Router>
                    <Switch>
                        <Route exact path="/page/:id" component={RouteParams(Page)} />
                        <Route exact path="/page/:id/:correctAnswers" component={RouteParams(Page)} />
                        <Route exact path="/result/:correctAnswers" component={RouteParams(ResultPage)} />

                        <Route component={() => (
                                <Page routeParams={{ id: 1 }} />
                            )}
                        />
                    </Switch>
                </Router>
            </div>
        </PagesDataContext.Provider>
    );
}

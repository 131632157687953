import React from 'react';
import classes from './Header.module.scss';

interface IProps {
    title: string;
    subtitle?: string;
}

export const Header: React.FC<IProps> = ({ title, subtitle }) => {
    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>{title}</div>
            {subtitle !== undefined ? (
                <div className={classes.subtitle}>{subtitle}</div>
            ) : null}
        </div>
    );    
};

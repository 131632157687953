import React, { useContext } from 'react';
import PagesDataContext from '../contexts/PagesData.context';
import classes from './ResultPage.module.scss';

interface IProps {
    routeParams: { 
        correctAnswers: number;
    }
};

export const ResultPage: React.FC<IProps> = ({ routeParams }) => {
    const succeeded: boolean = routeParams.correctAnswers >= useContext(PagesDataContext).correctAnswersRequired;
    return (
        <div className={succeeded ? classes.success : classes.failed} />
    );
};

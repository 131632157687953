import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Header } from './Header.component';
import { Answer } from './Answer.component';
import { PageID, IPage, asIPageLink, asIPotentialAnswer, asIQuestionPage } from '../interfaces/pages';
import PagesDataContext from '../contexts/PagesData.context';
import classes from './Page.module.scss';


interface IProps {
    routeParams: { 
        id: PageID
        correctAnswers?: string
    }
}

export const Page: React.FC<IProps> = ({ routeParams }) => {
    let pagesContext = useContext(PagesDataContext);
    let page = routeParams.id in pagesContext.pages ? pagesContext.pages[routeParams.id] : undefined;
    const questionPage = asIQuestionPage(page);
    const correctAnswers = routeParams.correctAnswers !== undefined ? parseInt(routeParams.correctAnswers) : 0;

    return page ? (
        <div>
            <Header title={page.title} subtitle={page.subtitle} />
            <div className={questionPage !== undefined ? classes.questions : classes.content}>
                {(() => {
                    return questionPage !== undefined ? questionPage.answers.map((a, idx) => (
                        <div key={idx} className={classes.p}>
                            <Answer question={questionPage} data={asIPotentialAnswer(a)} currentCorrect={correctAnswers} />
                        </div>
                    )) : (page as IPage).content.map((p, idx) => (
                        <div key={idx} className={classes.p}>
                            {(() => {
                                const link = asIPageLink(p);
                                return link !== undefined ? (
                                    <Link key={idx} className={classes.link} to={'/page/' + link.page}>{link.text}</Link>
                                ) : (
                                    <div>{p}</div>
                                )
                            })()}
                        </div>
                    ));
                })()}
            </div>
        </div>
    ) : (<></>);
};
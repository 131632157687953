import React from 'react';
import { Link } from 'react-router-dom';
import { IPotentialAnswer, IQuestionPage } from '../interfaces/pages';
import classes from './Answer.module.scss';

interface IProps {
    question: IQuestionPage;
    data: IPotentialAnswer;
    currentCorrect: number;
}

export const Answer: React.FC<IProps> = ({ data, question, currentCorrect }) => {
    const correct: number = currentCorrect + (data.correct ? 1 : 0);
    const nextPage: string = question.nextpage !== undefined ? '/page/' + question.nextpage : '/result';
    return (
        <Link className={classes.answer} to={nextPage + '/' + correct}>{data.text}</Link>
    );
};

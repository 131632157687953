export type PageID = number;

export interface IPageLink {
    page: PageID,
    text: string
};

export const isIPageLink = (o: any): boolean =>
    o instanceof Object && 'page' in o && 'text' in o;

export const asIPageLink = (o: any): IPageLink|undefined =>
    isIPageLink(o) ? o as IPageLink : undefined;


export interface IPage {
    title: string,
    subtitle?: string,
    content: (string|IPageLink)[]
};

export interface IPotentialAnswer {
    text: string,
    correct: boolean
};

export const asIPotentialAnswer = (q: IPotentialAnswer|string): IPotentialAnswer =>
    q instanceof Object ? q as IPotentialAnswer : { text: q as string, correct: false };

export interface IQuestionPage {
    title: string,
    subtitle?: string,
    answers: (string|IPotentialAnswer)[],
    nextpage?: PageID
};

export const isIQuestionPage = (o: any): boolean =>
    o instanceof Object && 'answers' in o;

export const asIQuestionPage = (o: any): IQuestionPage|undefined =>
    isIQuestionPage(o) ? o as IQuestionPage : undefined;

export interface IPagesData {
    'pages': {[key: number]: IPage|IQuestionPage},
    'correctAnswersRequired': number
};

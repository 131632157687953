import { createContext } from 'react';
import { IPagesData } from '../interfaces/pages'

export let EMPTY_PAGES_DATA: IPagesData = {
    pages: [],
    correctAnswersRequired: 0
};

export const PagesDataContext = createContext<IPagesData>(EMPTY_PAGES_DATA);
export default PagesDataContext;
